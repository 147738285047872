/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 5, 2020 */



@font-face {
    font-family: 'press_startregular';
    src: url('prstart-webfont.woff2') format('woff2'),
         url('prstart-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'press_start_kregular';
    src: url('prstartk-webfont.woff2') format('woff2'),
         url('prstartk-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}